import React from "react"
import { Box, Button, Flex, Heading, Text } from "@chakra-ui/react"
import { StaticImage } from "gatsby-plugin-image"
import { navigate } from "gatsby"

const styles = {
  root: {
    margin: "40px 0px 53px 0px ",
  },
  button: {
    marginTop: "24px",
    padding: "8px 16px",
    background: "#129459",
    height: { base: "40px", md: "40px", lg: "56px" },
    width: "100%",
    borderRadius: "4px",
    color: "#F1F1F1",
    fontSize: "16px",
    lineHeight: "24px",
    fontWeight: "bold",
    outline: "none",
    "&:hover,&:active,&:focus": {
      bg: "#129459",
      outline: "none",
      border: "none",
    },
  },
  flex: {
    flexDirection: {
      base: "column",
      md: "column",
      lg: "row",
    },
    alignItems: "center",
    justifyContent: "center",
  },
  heading: {
    textAlign: "center",
    fontSize: { base: "24px", md: "24px", lg: "48px" },
    lineHeight: { base: "32px", md: "32px", lg: "48px" },
  },
  image: {
    width: { base: "96.57px", md: "96.57", lg: "241.41px" },
    marginRight: { base: "0px", md: "0px", lg: "77.5px" },
    marginBottom: { base: "24px", md: "24px", lg: "0px" },
  },

  text: {
    fontSize: "16px",
    lineHeight: "24px",
    fontWeight: 400,
    marginTop: "24px",
    textAlign: "center",
  },
}

const navigateToHome = () => navigate("/")

const ErrorPage = () => {
  return (
    <Box sx={styles.root}>
      <Flex sx={styles.flex}>
        <Box sx={styles.image}>
          <StaticImage
            alt="404 Page Error"
            src="../../images/404/404-img.png"
            placeholder="blurred"
          />
        </Box>
        <Box sx={{ width: "315px" }}>
          <Heading sx={styles.heading}>404</Heading>
          <Text sx={styles.text}>
            Well this is awkward. The page you are looking for doesn’t exist.
            <Text sx={{ paddingBottom: "25px" }}></Text>
            We wish we knew what to tell you but we’d have to get a second
            opinion. Let’s get you home and back on the right track.
          </Text>
          <Button sx={styles.button} onClick={navigateToHome}>
            Home
          </Button>
        </Box>
      </Flex>
    </Box>
  )
}
export default ErrorPage
