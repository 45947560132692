import React from "react"
import ErrorPage from "../components/404/error-page"
import Layout from "../components/layout"

const NotFoundPage = props => {
  return (
    <Layout location={props.location}>
      <ErrorPage />
    </Layout>
  )
}

export default NotFoundPage
